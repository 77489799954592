import { defineStore } from 'pinia';
import { type ComputedRef, computed, ref } from 'vue';
import {
  Timestamp, collection, getFirestore, onSnapshot, orderBy, query,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { type Car, type Entretien, useCarsStore } from './cars';
import { useTypeEntretienStore } from './typeEntretien';

export interface Alerte {
  car: Car
  entretienName: string
  currentKm?: number
  targetKm?: number
  inKm?: number
  targetDate?: Date
  inDays?: number
}

export const useAlerteStore = defineStore('alertes', () => {
  const db = getFirestore();

  const carsStore = useCarsStore();
  const repairTypesStore = useTypeEntretienStore();
  const lastRepairs = ref<Entretien[]>([]);
  let unsubscribe: (() => void) | undefined;

  async function bindAlertes() {
    if (unsubscribe) return;

    const qb = query(collection(db, 'repairs'), orderBy('repairDate', 'desc'));
    unsubscribe = onSnapshot(qb, (snapshot) => {
      // get last entretien par trie date et filtre par id type entretien
      lastRepairs.value = [];
      snapshot.docs.forEach((doc) => {
        const entretien: Entretien = (doc.data() as Entretien);
        if (!lastRepairs.value.find((e) => e.repairType === entretien.repairType && e.carId === entretien.carId)) { lastRepairs.value.push(entretien); }
      });
    });
  }

  function checkDateAlerte(lastEntretienDate: Date, targetDuration: number): boolean {
    const monthsDiff = DateTime.now().diff(DateTime.fromJSDate(lastEntretienDate), 'months');
    return monthsDiff.as('months') >= (targetDuration - 1);
  }

  function checkKmAlerte(currentKm: number, lastEntretienKm: number, targetKm: number): boolean {
    return (currentKm >= (targetKm + lastEntretienKm - 5000));
  }

  function getAlertsByCar(car: Car): Alerte[] {
    const alerts: Alerte[] = [];

    repairTypesStore.types.forEach((type) => {
      const lastRepair: Entretien = lastRepairs.value.find((e) => e.repairType === type.id && +e.carId === +car.id)
                ?? {
                  repairMileage: 0,
                  repairDate: car.registrationDate || Timestamp.fromDate(new Date()),
                  carId: parseInt(car.id),
                  repairType: type.id!,
                };

      if (type.km && checkKmAlerte(car.currentKm || 0, lastRepair.repairMileage, type.km)) {
        const targetKm = lastRepair.repairMileage + type.km;
        alerts.push({
          car,
          entretienName: type.name,
          currentKm: car.currentKm,
          targetKm,
          inKm: targetKm - car.currentKm,
        });
      }

      if (type.duration && checkDateAlerte(lastRepair.repairDate.toDate(), type.duration)) {
        const targetDate = DateTime.fromJSDate(lastRepair.repairDate.toDate()).plus({ month: type.duration });
        alerts.push({
          car,
          entretienName: type.name,
          currentKm: car.currentKm,
          targetDate: targetDate.toJSDate(),
          inDays: targetDate.diffNow().as('days'),
        });
      }
    });

    return alerts;
  }

  const alertes: ComputedRef<Alerte[]> = computed(() => {
    const result: Alerte[] = [];

    carsStore.sortedCars.forEach((car) => {
      result.push(...getAlertsByCar(car));
    });

    return result;
  });

  return {
    alertes,
    bindAlertes,
    lastRepairs,
  };
});
