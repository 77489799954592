import { defineStore } from 'pinia';
import { reactive } from 'vue';

interface Notification {
  id: number
  type: 'success' | 'error' | 'loading'
  title: string
  message: string
  timeout?: number
  unsubscribe?: NodeJS.Timeout
}

const useNotificationsStore = defineStore('notifications', () => {
  const list = reactive<Record<number, Notification>>({});

  const dismiss = (id: number) => {
    if (list[id]?.unsubscribe) clearInterval(list[id].unsubscribe);
    delete list[id];
  };

  const show = (type: 'success' | 'error' | 'loading', title: string, message: string, timeout?: number): number => {
    const id = Date.now();
    const notification = {
      id, type, title, message, timeout: 0,
    };
    list[id] = notification;

    if (timeout) {
      list[id].unsubscribe = setInterval(() => {
        list[id].timeout = list[id].timeout! + 0.5;

        if (list[id].timeout! >= 100) {
          dismiss(id);
        }
      }, (timeout * 1000) / 200);
    }

    return id;
  };

  const success = (title: string, message: string, timeout: number = 1): number => show('success', title, message, timeout);

  const loading = (title: string, message: string = 'Cela prendra quelques secondes...'): number => show('loading', title, message);

  const error = (title: string, message: string, timeout: number = 5): number => show('error', title, message, timeout);

  const handleResponse = async (res: Response, successMessage: string): Promise<boolean> => {
    if (res.ok) {
      success('Opération réussie', successMessage);
      return true;
    }
    try {
      const body = await res.json();
      error('Erreur', body.message ? body.message : 'Une erreur est survenue');
    } catch (e) {
      error('Erreur', 'Une erreur est survenue');
    }
    return false;
  };

  return {
    list, show, dismiss, success, error, loading, handleResponse,
  };
});

export default useNotificationsStore;
