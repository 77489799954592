import { createApp } from 'vue';
import { createPinia } from 'pinia';
import FloatingVue from 'floating-vue';
import { initializeApp } from 'firebase/app';
import * as Sentry from '@sentry/vue';
import { getAnalytics } from 'firebase/analytics';
import {
  connectFirestoreEmulator,
  getFirestore,
} from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import App from './App.vue';
import router from './router';
import './assets/main.css';
import DashboardLayout from './components/DashboardLayout.vue';
import EmptyLayout from './components/EmptyLayout.vue';
import firebaseConfig from './firebase';
import 'floating-vue/dist/style.css';

initializeApp(firebaseConfig);
getAnalytics();

if (import.meta.env.DEV) {
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
  connectStorageEmulator(getStorage(), 'localhost', 9199);
}

const pinia = createPinia();
const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://c46bb703b6795ba3d7ad282e5c1b4635@o4506359203233792.ingest.sentry.io/4506359205199872',
  environment: import.meta.env.DEV ? 'dev' : 'production',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
    new Sentry.Feedback({
      autoInject: false,
      colorScheme: 'light',
      buttonLabel: '',
      emailLabel: 'Email',
      emailPlaceholder: 'votre.email@exemple.com',
      nameLabel: 'Nom',
      namePlaceholder: 'Votre nom',
      messageLabel: 'Description',
      messagePlaceholder: 'Quel est le bug ? Que faisiez-vous ? Quel était le résultat attendu ?',
      showBranding: false,
      formTitle: 'Signaler un bug',
      cancelButtonLabel: 'Annuler',
      submitButtonLabel: 'Envoyer',
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.component('DefaultLayout', DashboardLayout);
app.component('EmptyLayout', EmptyLayout);

app.use(router);
app.use(pinia);
app.use(FloatingVue, { themes: { tooltip: { triggers: ['hover', 'focus', 'touch', 'click'] } } });

app.mount('#app');
