import { defineStore } from 'pinia';
import { ref } from 'vue';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import useNotificationsStore from './notifications';
import fetchAuthenticated from '../lib/http/fetchAuthenticated';

export interface TypeEntretien {
  id?: string
  name: string
  duration?: number
  km?: number,
  position?: number,
}

export const useTypeEntretienStore = defineStore('type-entretien', () => {
  const db = getFirestore();

  const types = ref<TypeEntretien[]>([]);
  const notifications = useNotificationsStore();
  let unsubscribe: (() => void) | undefined;

  function bindTypes() {
    if (unsubscribe) return;
    unsubscribe = onSnapshot(collection(db, 'repair-types'), (snapshot) => {
      const typeData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      } as TypeEntretien));
      types.value = typeData.sort((a, b) => (a.position || 0) - (b.position || 0));
    });
  }

  async function saveTypeEntretien(payload: TypeEntretien) {
    const notifId = notifications.loading('Sauvegarde du type d\'entretien en cours');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/settings/repairs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'Type d\'entretien enregistré');
  }

  async function updatePositions(payload: Array<{ id: string, position: number }>) {
    const notifId = notifications.loading('Mise à jour des positions en cours...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/settings/repairs/position`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'Position mis à jour');
  }

  return {
    types,
    bindTypes,
    saveTypeEntretien,
    updatePositions,
  };
});
