import { getAuth } from 'firebase/auth';

export default async function fetchAuthenticated(input: RequestInfo, init?: RequestInit): Promise<Response> {
  const auth = getAuth();

  if (!auth.currentUser) throw new Error('User not logged in');

  const idToken = await auth.currentUser.getIdToken();

  const headers = new Headers(init?.headers);
  headers.set('Authorization', idToken);

  return fetch(input, {
    ...init,
    headers,
  });
}
