import { defineStore } from 'pinia';
import { ref } from 'vue';
import { type User, getAuth } from 'firebase/auth';
import { Md5 } from 'ts-md5';
import * as Sentry from '@sentry/vue';
import useNotificationsStore from './notifications';
import fetchAuthenticated from '../lib/http/fetchAuthenticated';

const useUserStore = defineStore('user', () => {
  const user = ref<User | false>(false);
  const email = ref<string | null>(null);
  const notifications = useNotificationsStore();

  const imageUrl = ref("'https://www.gravatar.com/avatar/?d=identicon");

  const login = () => {
    const auth = getAuth();
    const { currentUser } = auth;
    if (currentUser) {
      user.value = currentUser;
      email.value = currentUser.email;
      if (currentUser.email) { imageUrl.value = `https://www.gravatar.com/avatar/${Md5.hashStr(currentUser.email.trim().toLowerCase())}?d=identicon`; }

      Sentry.setUser({
        id: currentUser.uid,
        email: currentUser.email ?? undefined,
        username: currentUser.displayName ?? undefined,
      });

      return currentUser;
    }
    return false;
  };

  const logout = async () => {
    const auth = getAuth();
    await auth.signOut();
    user.value = false;
    imageUrl.value = 'https://www.gravatar.com/avatar/?d=identicon';
    email.value = null;
  };

  async function getListUsers() {
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  }

  async function saveUser(ur: { uid: string, email: string | undefined, displayName: string | undefined, password: string | undefined, }) {
    const { uid, ...data } = ur;
    const notifId = notifications.loading('Ajout d\' un utilisateur...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'Utilisateur créé avec succès');
  }

  async function deleteUser(uid: string) {
    const notifId = notifications.loading('Suppression d\'un utilisateur...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/users/${uid}`, {
      method: 'DELETE',
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'Utilisateur supprimé');
  }

  return {
    user, login, logout, imageUrl, email, getListUsers, saveUser, deleteUser,
  };
});

export default useUserStore;
