import {
  Timestamp,
  collection, getFirestore, onSnapshot, orderBy, query, where,
} from 'firebase/firestore';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import fetchAuthenticated from '../lib/http/fetchAuthenticated';
import useNotificationsStore from './notifications';

export type RentalAlertType = 'km_limit_reached' | 'no_km' | 'zero_km' | 'no_checkout_after_limit' | 'late_checkout';

export interface AlertRental {
  id: string;
  type: RentalAlertType;
  archived: boolean;
  rentalId: number;
  time: Date;
  details?: Record<string, unknown>;
}

export const useRentalAlertStore = defineStore('rental-alerts', () => {
  const db = getFirestore();
  const alerts = ref<AlertRental[]>([]);
  let unsubscribe: (() => void) | undefined;
  const notifications = useNotificationsStore();

  function bindRentalAlerts() {
    if (unsubscribe) return;

    unsubscribe = onSnapshot(query(collection(db, 'rental-alerts'), where('archived', '==', false), orderBy('time', 'desc')), (snapshot) => {
      alerts.value = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        time: (doc.get('time') as Timestamp | undefined)?.toDate(),
      } as AlertRental));
    });
  }

  async function archiveAlerts(alertIds: string[]) {
    const notifId = notifications.loading('Archivage des alertes...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/rental-alerts/bulk-archive`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ alertIds }),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'Alerte(s) marquée(s) comme lue');
  }

  async function sendGAZeroKmMessage(alertId: string) {
    const notifId = notifications.loading('Envoi du message à GetAround...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/rental-alerts/send-ga-zero-km-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ alertId }),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'Message envoyé à GetAround');
  }

  return {
    alerts,
    bindRentalAlerts,
    archiveAlerts,
    sendGAZeroKmMessage,
  };
});
