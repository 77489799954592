<template>
  <div
    v-if="config.gaSessionIsExpired"
    class="rounded-md bg-red-50 p-4 mb-4 border-l-4 border-red-600"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon
          class="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-700">
          Le cookie de session permettant de s'authentifier à GetAround
          pour télécharger les photos de location a expiré.
          <a
            href="#"
            class="font-medium text-red-700 underline hover:text-red-600"
            @click="openCustomModal()"
          >Mettez-le
            à jour pour obtenir les dernières photos de location.</a>
        </h3>
      </div>
    </div>
  </div>
  <CustomModal
    title="Ré-authentification à GetAround"
    :open="isOpen"
    :button-action="save"
    button-label="Valider"
    :icon="KeyIcon"
    @close="isOpen = false"
  >
    <form @submit.prevent="save">
      <label
        for="repairDate"
        class="text-xs"
      >
        Pour télécharger les photos de location, il est nécessaire de s'authentifier à GetAround. Pour
        cela, suivre la procédure ci-dessous :
        <ol class="list-decimal ml-5 mb-3">
          <li>Ajouter l'extension <a
            href="https://chrome.google.com/webstore/detail/copy-cookies/jcbpglbplpblnagieibnemmkiamekcdg"
            target="_blank"
            class="text-indigo-600"
          >Copy Cookies</a> à Chrome</li>
          <li>Se connecter à <a
            href="https://fr.getaround.com/"
            target="_blank"
            class="text-indigo-600"
          >GetAround</a></li>
          <li>Depuis GetAround, cliquer sur l'extension Copy Cookies ou utiliser le raccourci Ctrl+Shift+K</li>
          <li>Coller dans le champ ci-dessous et valider.</li>
        </ol>
      </label>
      <div class="mt-2">
        <textarea
          id="repairDate"
          v-model="form.cookies"
          placeholder="Clic-droit > coller ou Ctrl+V"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-20"
          type="text"
          name="repairDate"
        />
      </div>
    </form>
  </CustomModal>
</template>

<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { KeyIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import useConfigStore from '../stores/config';
import CustomModal from './CustomModal.vue';

const isOpen = ref<boolean>(false);
const form = ref({ cookies: '' });
const config = useConfigStore();
config.bindConfig();
function openCustomModal() {
  isOpen.value = true;
}
function save() {
  isOpen.value = false;
  let value = '';
  try {
    const json = JSON.parse(form.value.cookies) as Array<{ name: string, value: string }>;
    const cookie = json.find((c) => c.name === '_session_id')?.value;
    if (cookie) {
      value = cookie;
    } else {
      throw new Error('Cookie not found');
    }
  } catch (error) {
    value = form.value.cookies;
  }
  config.updateConfig(value);
}

</script>
