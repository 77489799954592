<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAlerteStore } from './stores/alertes';

const defaultLayout = 'default';

const { currentRoute } = useRouter();

const layout = computed(
  () => `${currentRoute.value.meta.layout || defaultLayout}-layout`,
);

const alertsStore = useAlerteStore();
alertsStore.bindAlertes();
</script>

<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
