import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import useNotificationsStore from './notifications';
import fetchAuthenticated from '../lib/http/fetchAuthenticated';

const useConfigStore = defineStore('config', () => {
  const db = getFirestore();
  const gaSessionIsExpired = ref<boolean>(false);
  let unsubscribe: (() => void) | undefined;

  async function bindConfig() {
    if (unsubscribe) return;
    unsubscribe = onSnapshot(doc(db, 'config', 'getaround_session'), (document) => {
      if (document.exists()) {
        const data = document.data() as ({ expired: boolean });
        gaSessionIsExpired.value = data.expired;
      }
    });
  }

  const notifications = useNotificationsStore();

  async function updateConfig(token: string) {
    const notifId = notifications.loading('Mise à jour de l\'authentification GetAround en cours');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/config/getaround-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'Mise à jour terminée');
  }

  return {
    gaSessionIsExpired,
    bindConfig,
    updateConfig,
  };
});

export default useConfigStore;
