// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBW2bcZOLnXRzK0vnfwl0P8yafzZ9QjxCo',
  authDomain: 'coolcars-5c4be.firebaseapp.com',
  projectId: 'coolcars-5c4be',
  storageBucket: 'coolcars-5c4be.appspot.com',
  messagingSenderId: '382968199700',
  appId: '1:382968199700:web:e0dc7856bfd80d9beac501',
  measurementId: 'G-50CLSJHQRW',
};

export default firebaseConfig;
